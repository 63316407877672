import { css } from 'linaria';
import '@jetshop/ui/Theme/sanitizeCss';

css`
  :global() {
    html,
    body {
      height: 100%;
      font-family: 'HCo Gotham SSm', sans-serif;
      font-size: 16px;
      line-height: 1;
      font-weight: 400;
      letter-spacing: 0px;
      word-spacing: 0px;
    }

    html,
    body,
    #root {
      min-height: 100%;
    }

    #root {
      display: flex;
      flex-direction: column;
    }

    button {
      cursor: pointer;
      padding: 0px;
      border: none;
      font: inherit;
    }

    ol,
    ul,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    h1,
    h2,
    h3,
    h4 {
      font-weight: 600;
    }

    h1 {
      font-size: 2.25rem;
      line-height: 2.75rem;
    }

    h2 {
      font-size: 1.625rem;
      line-height: 2rem;
    }

    h3 {
      line-height: 1.7rem;
    }

    h5 {
      /*
        Use proper rem, but uce calc to easily set font size in relation to the base font size
        https://www.joshwcomeau.com/css/surprising-truth-about-pixels-and-accessibility/#calculated-values-14
      */
      font-size: calc(24rem / 16);
      font-weight: 300;
      line-height: 1.7;
    }

    p,
    dd {
      margin: 0;
      padding: 0;
    }

    p {
      font-weight: 400;
      line-height: 1.5rem;
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    input {
      -webkit-appearance: none;
    }

    .ReactModal__Body--open {
      overflow: hidden;
    }

    .ReactModal__Overlay--after-open {
      overflow: scroll;
    }

    body {
      -webkit-font-smoothing: antialiased;
    }

    /*
       * apply a natural box layout model to all elements, but allowing components
       * to change */

    html {
      box-sizing: border-box;
      color: #122945;
    }

    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }

    @media screen and (max-width: 50rem) {
      #purechat-container {
        display: none !important;
      }
    }
    /* Fix for irritating iframe in dev */

    body > iframe {
      display: none !important;
    }

    body.allow-overflow {
      overflow: visible !important;
    }
  }
`;
